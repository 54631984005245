<template>
  <apexchart
    ref="chartRef"
    type="donut"
    :series="series"
    :options="chartOptions"
  ></apexchart>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "Donut Chart",
  props: {
    labels: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    colours: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const chartOptions = ref({
      labels: props.labels,
      dataLabels: {
        enabled: false,
      },

      responsive: [
        {
          breakpoint: 600,
          options: {
            legend: {
              show: false,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    value: {
                      fontSize: "10px",
                    },
                    total: {
                      fontSize: "8px",
                    },
                  },
                },
              },
            },
          },
        },
      ],
      fill: {
        opacity: 1,
      },
      colors: props.colours,
      plotOptions: {
        pie: {
          expandOnClick: true,
          donut: {
            size: "60%",
            background: "transparent",
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 400,
                color: undefined,
                offsetY: 3,
                formatter: function (val) {
                  return formatNumberIntoHumanizeForm(val);
                },
              },
              total: {
                show: true,
                showAlways: true,
                label: "Fanbase Size",
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#373d3f",
                formatter: function (w) {
                  let total = w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                  store.dispatch("ArtistModule/setFanbase", total);
                  return formatNumberIntoHumanizeForm(total);
                },
              },
            },
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return formatNumberIntoHumanizeForm(value);
          },
        },
      },
    });
    return { chartOptions };
  },
};
</script>
<style lang="scss" scoped></style>
