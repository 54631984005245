<template>
  <CardToolbar
    description="Artist's which are similar to each other in genre"
    title="Similar Artists"
    :show-toolbar-buttons="false"
  >
    <template v-slot:body>
      <el-skeleton animated :rows="10" v-if="loading" />
      <el-row v-else>
        <el-col
          class="text-center similar-artist"
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="artist in similarArtists"
          :key="artist.uuid"
        >
          <router-link
            :disabled="true"
            @click="openArtistProfileDrawer(artist.uuid)"
            to=""
            ><el-avatar :size="100" :src="artist.imageUrl"></el-avatar>
            <h3 class="artist-name truncate">
              {{ artist.name.toUpperCase() }}
            </h3></router-link
          >
        </el-col>
      </el-row>
    </template>
  </CardToolbar>
</template>
<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import { getSimilarArtist } from "@/api/artists.api";
import CardToolbar from "../../common/components/CardToolbar";

export default {
  name: "SimilarArtists",
  components: { CardToolbar },
  setup() {
    const route = useRoute();
    const artistID = route.params.uuid;
    const similarArtists = ref([]);
    const loading = ref(true);

    onMounted(async () => {
      try {
        const { data } = await getSimilarArtist(artistID);
        similarArtists.value = data;
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });
    return { similarArtists, loading };
  },
};
</script>
<style lang="scss" scoped>
.similar-artist {
  padding: 1.5rem;
}
</style>
