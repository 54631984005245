<template>
  <el-card class="platform-fanbase" shadow="hover">
    <div class="d-flex flex-column align-items-center justify-content-between">
      <div class="platform d-flex">
        <el-image
          class="icon"
          :src="'/media/svg/social-logos/' + platform + '.svg'"
        />
        <span class="platform-name mt-md-1 fw-bold">
          {{ platform.toUpperCase() }}
        </span>
      </div>
      <div class="d-flex text-center flex-column mt-5 w-100">
        <span
          class="followers-count fw-bold truncate w-100"
          data-tag="span"
          title=""
          data-tooltip="true"
          :data-text="followers"
          >{{ followers }}</span
        >
        <span class="footer-text">{{ indicator }}</span>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "FanbasePlatform",
  props: {
    followers: {
      type: String,
      required: true,
    },
    platform: {
      type: String,
      required: true,
    },
    hasData: {
      type: Boolean,
      required: true,
    },
    indicator: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.platform-fanbase {
  .platform {
    .icon {
      width: 20px;
      height: 20px;
    }

    .platform-name {
      color: #9fa2aa;
      font-size: 0.8rem;
      margin-left: 0.5em;
    }
  }

  .footer-text {
    color: #9fa2aa;
    font-size: 0.7rem;
  }

  .followers-count {
    font-size: 1.3rem;
  }
}
</style>
