<template>
  <div ref="lazyLoadComponent">
    <div v-if="isRendered">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "LazyLoadComponent",
  data() {
    return {
      isRendered: false,
    };
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.onScroll);
      this.onScroll();
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const componentToBeLoaded = this.$refs["lazyLoadComponent"];
      if (componentToBeLoaded) {
        let marginTopSimilarArtists =
          componentToBeLoaded.getBoundingClientRect().top;
        let innerHeight = window.innerHeight;
        if (marginTopSimilarArtists - innerHeight < 0 && !this.isRendered) {
          this.isRendered = true;
        }
      }
    },
  },
};
</script>

<style scoped></style>
