<template>
  <FanbaseDistribution />

  <!--  <LazyLoadComponent>-->
  <!--    <FanbaseGrowth />-->
  <!--  </LazyLoadComponent>-->

  <Top10Tracks />

  <LazyLoadComponent>
    <SocialAnalytics :uuid="uuid" platform="spotify" />
  </LazyLoadComponent>
  <!--  <LazyLoadComponent>-->
  <!--    <SocialAnalytics :uuid="uuid" platform="youtube" />-->
  <!--  </LazyLoadComponent>-->

  <LazyLoadComponent>
    <ListenersByCountry />
  </LazyLoadComponent>

  <el-row :gutter="24">
    <el-col :xs="24" :lg="12">
      <LazyLoadComponent>
        <NewSpotifyFollowers />
      </LazyLoadComponent>
    </el-col>

    <el-col :xs="24" :lg="12">
      <LazyLoadComponent>
        <SpotifyPopularity />
      </LazyLoadComponent>
    </el-col>
  </el-row>

  <LazyLoadComponent>
    <SimilarArtists />
  </LazyLoadComponent>

  <LazyLoadComponent></LazyLoadComponent>
</template>
<script>
import { ElementAnimateUtil } from "../../assets/ts/_utils/index";
import FanbaseDistribution from "../components/FanbaseDistribution";
import ListenersByCountry from "../components/ListenersByCountry";
import SimilarArtists from "../components/SimilarArtists";
import SocialAnalytics from "../components/SocialAnalytics";
import Top10Tracks from "../components/Top10Tracks";
import LazyLoadComponent from "../../common/components/LazyLoadComponent";
import NewSpotifyFollowers from "@/artists/components/NewSpotifyFollowers";
import SpotifyPopularity from "@/artists/components/SpotifyPopularity";

export default {
  components: {
    FanbaseDistribution,
    // FanbaseGrowth,
    Top10Tracks,
    ListenersByCountry,
    LazyLoadComponent,
    NewSpotifyFollowers,
    SpotifyPopularity,
    SocialAnalytics,
    SimilarArtists,
  },
  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
  },
  watch: {
    uuid() {
      ElementAnimateUtil.scrollTop(null, 1000);
    },
  },
};
</script>
<style lang="scss" scoped></style>
